import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: 44,
    height: 50,
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14
    }
  },
  rootSmall: {
    height: 34
  },
  choosePage: {
    display: 'inline-block',
    marginRight: 20,
    color: 'inherit'
  },
  choosePageSmall: {
    fontSize: '11px',
    fontWeight: '400',
    color: theme.palette.black.main
  },
  pageCounter: {
    fontSize: '11px',
    fontWeight: '400',
    color: theme.palette.black.main
  },
  pageInput: {
    height: 28,
    width: 60,
    marginTop: 5,
    '& .MuiFormHelperText-contained': {
      width: '200px !important',
      margin: '10px 0 0 -65px'
    }
  },
  pageInputSmall: {
    width: 45,
    '& .MuiOutlinedInput-input': {
      fontSize: '11px'
    },
    '& .MuiInputBase-root': {
      width: '40px',
      height: '24px'
    }
  },
  mainActions: {
    display: 'flex'
  }
}));

export default useStyles;
