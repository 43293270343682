import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { dealStatus } from 'src/MainLayout/DealPage/TabPages/Deal/DealPage/DealTable/utils';
import { areInputValuesNotEqual } from './validation';
import { ColumnHeader } from '../components/TableWithFilters/ColumnHeader';
import { DATE_TYPE, NUMBER_TYPE } from './constants/stringConstants';

export const cutStr = (str, len) => {
  if (!str) {
    return;
  }
  const cutedStr = str.substr(0, len);
  // eslint-disable-next-line consistent-return
  return str.length > len ? `${cutedStr} ...` : str;
};

export const sortOrdersArray = (numArray, fieldName) =>
  numArray.sort((a, b) => a[fieldName] - b[fieldName]);

export const getDateValue = (value) => (value ? moment(value).format('YYYY-MMM-DD') : '');
export const getDateTimeValue = (value) => (value ? moment(value).format('YYYY-MMM-DD HH:mm') : '');
export const getStatusValue = (value) => dealStatus.get(value);
export const isToday = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
};

export const parseFloatValueAsString = (value) => {
  const parsed = parseFloat(value);
  return Number.isNaN(parsed) ? value : parsed.toString();
};

export const getCellComponentCustomFileds = (cell, id, type) => {
  if (cell.row.customFields) {
    if (type === DATE_TYPE) {
      return getDateValue(cell.row.customFields[id]);
    }
    if (type === NUMBER_TYPE) {
      return parseFloatValueAsString(cell.row.customFields[id]);
    }
    return cell.row.customFields[id];
  }
  return '';
};
export const trimValue = (value) => value && value.trim();

export const removeExtraSpaceFromObjectValues = (obj) => {
  const cachedObject = { ...obj };
  Object.keys(cachedObject).forEach((key) => {
    cachedObject[key] =
      typeof cachedObject[key] === 'string' &&
      (cachedObject[key] !== null || cachedObject[key] !== undefined)
        ? trimValue(obj[key])
        : cachedObject[key];
  });
  return cachedObject;
};

export const isNumberValuePresent = (value) => value !== null && value !== undefined;

export const isShrink = (value) =>
  (Boolean(value) ? true : undefined) || (value === 0 ? true : undefined);

export const isArrayEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();

export const areArraysEqual = (x, y) => isArrayEqual(x, y) && isArrayEqual(y, x);

export const isArrayNotEqual = (x, y) => !isArrayEqual(x, y);

export const areStringsEqualIgnoreCase = (x, y) => x.toLowerCase() === y.toLowerCase();

export const arrayContainsAllElements = (arr1, arr2) =>
  arr2.every((element) => arr1.includes(element));

export const areValuesNotEqual = (prevValue, value) => {
  const normalizedPrevValue = prevValue ?? '';
  const normalizedValue = value ?? '';

  if (typeof normalizedPrevValue === 'number') {
    return areInputValuesNotEqual(`${normalizedPrevValue}`, normalizedValue);
  }

  if (typeof normalizedPrevValue === 'boolean') {
    return normalizedPrevValue !== normalizedValue;
  }

  if (typeof normalizedValue === 'object') {
    return !!normalizedPrevValue.id
      ? normalizedPrevValue.id !== normalizedValue.id
      : !areArraysEqual(prevValue, value);
  }

  return areInputValuesNotEqual(normalizedPrevValue, normalizedValue);
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    return areArraysEqual(obj1, obj2);
  }

  if (obj1 instanceof Map && obj2 instanceof Map) {
    if (obj1.size !== obj2.size) return false;
    let isEqual = true;
    obj1.forEach((value, key) => {
      if (!deepEqual(value, obj2.get(key))) {
        isEqual = false;
      }
    });
    return isEqual;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
};

export const getCustomFieldDefinition = (customField, filterOperators) => ({
  id: customField.id,
  headerName: `${customField.name}`,
  renderHeader: () => <ColumnHeader title={customField.name} />,
  field: customField.name,
  type: customField.type,
  filterOperators: filterOperators(customField.type),
  description: `${customField.name}`,
  isKeyField: customField.isKeyField,
  valueGetter: (cellData) =>
    getCellComponentCustomFileds(cellData, customField.id, customField.type)
});

export const getFirstElementByClassName = (name) => {
  const tooltipContainers = document.getElementsByClassName(name);
  return tooltipContainers.length > 0 ? tooltipContainers[0] : null;
};

export const highlightText = (text, searchValue) => {
  if (!searchValue) {
    return text;
  }
  if (!text) {
    return text;
  }

  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const normalizeAndCleanSearchValue = (string) =>
    string
      .replace(/[“”]/g, '"')
      .replace(/[‘’ʼ]/g, "'")
      .replace(/^["']|["']$/g, '')
      .trim();

  const cleanedSearchValue = escapeRegExp(normalizeAndCleanSearchValue(searchValue));
  const normalizedText = text.trim().replace(/\s+/g, ' ');
  const parts = normalizedText.split(new RegExp(`(${cleanedSearchValue})`, 'gi'));
  return (
    <>
      {parts.map((part) =>
        part.toLowerCase() === cleanedSearchValue?.toLowerCase() ||
        part.toLowerCase() === searchValue.toLowerCase() ? (
          <span
            style={{
              padding: '4px',
              borderRadius: '4px',
              fontStyle: 'italic',
              backgroundColor: '#FFDBDB'
            }}
          >
            {part}
          </span>
        ) : (
          <span>{part}</span>
        )
      )}
    </>
  );
};

export const highlightTextWithTags = (text) => {
  if (!text) {
    return text;
  }

  const normalizedText = text.trim().replace(/\s+/g, ' ');

  const highlightedText = normalizedText.replace(
    /<em>(.*?)<\/em>/gi,
    (match, p1) =>
      `<span style="padding: 4px; border-radius: 4px; font-style: italic; background-color: #FFDBDB;">${p1}</span>`
  );

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};
