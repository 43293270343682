import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { EMPTY_VALUE } from './constants/stringConstants';
import { overflowStyle } from './overflowStyle';

export const getItem = (
  key,
  value,
  selectedFilters,
  handleSelectOne,
  style,
  classes,
  disableRipple
) => {
  const itemRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const item = itemRef.current;
    if (item) {
      setIsEllipsisActive(item.scrollWidth > item.clientWidth);
    }
  }, []);

  return (
    <FormControlLabel
      key={key}
      margin="normal"
      style={style}
      className={classes.labelRow}
      control={
        <Checkbox
          disableRipple={disableRipple}
          color="primary"
          checked={selectedFilters ? selectedFilters.indexOf(key) !== -1 : false}
          onChange={(event) => handleSelectOne(event, key)}
          classes={{ root: classes.checkboxRoot }}
        />
      }
      label={
        <Tooltip disableInteractive title={isEllipsisActive ? value : ''} placement="top">
          <p ref={itemRef} style={{ width: 330, ...overflowStyle }} className={classes.labelRow}>
            {value || EMPTY_VALUE}
          </p>
        </Tooltip>
      }
      labelPlacement="end"
    />
  );
};

export const getListItemMultipleColumn =
  (selectedFilters, selectedFilteredColumn, handleSelectOne, classes) =>
  // eslint-disable-next-line react/prop-types
  ({ index, style, data }) => {
    const item = data[index];
    return getItem(
      item,
      item,
      selectedFilters[selectedFilteredColumn],
      handleSelectOne,
      style,
      classes
    );
  };

export const getListItem =
  (selectedFilters, handleSelectOne, classes, disableRipple) =>
  // eslint-disable-next-line react/prop-types
  ({ index, style, data }) => {
    const item = data[index];
    const id = item.id || item.dealId;
    const name = item.dealName || item.name;
    return getItem(id, name, selectedFilters, handleSelectOne, style, classes, disableRipple);
  };
