import * as constants from './deal.constants';

export const getAllDeals = (deals) => ({
  type: constants.GET_ALL_DEALS,
  payload: deals
});

export const getDeals = () => ({
  type: constants.GET_DEALS
});

export const loadDealsData = (loading) => ({
  type: constants.LOAD_DEALS_DATA,
  payload: loading
});

export const enableShowDealPortfolioStatus = (enabledDealIds) => ({
  type: constants.ENABLE_SHOW_DEAL_PORTFOLIO_STATUS,
  payload: { enabledDealIds }
});

export const enableShowDealPortfolioStatusTopics = (enabledDealIds) => ({
  type: constants.ENABLE_SHOW_DEAL_PORTFOLIO_STATUS_TOPICS,
  payload: { enabledDealIds }
});

export const getDealsSuccess = (items) => ({
  type: constants.GET_DEALS_SUCCESS,
  payload: items
});

export const getDealFiltersList = () => ({
  type: constants.GET_DEAL_FILTERS_LIST
});

export const getDealFiltersListSuccess = (items) => ({
  type: constants.GET_DEAL_FILTERS_LIST_SUCCESS,
  payload: items
});

export const getDealsError = (error) => ({
  type: constants.GET_DEALS_ERROR,
  error
});

export const saveFiles = (files) => ({
  type: constants.SAVE_FILES,
  payload: files
});

export const saveFilesSuccess = (files) => ({
  type: constants.SAVE_FILES_SUCCESS,
  payload: files
});

export const saveFilesError = (error) => ({
  type: constants.SAVE_FILES_ERROR,
  error
});

export const getPortfolioList = () => ({
  type: constants.GET_PORTFOLIO_LIST
});

export const getPortfolioListSuccess = (items) => ({
  type: constants.GET_PORTFOLIO_LIST_SUCCESS,
  payload: items
});

export const deletePortfolioFromList = (portfolioId) => ({
  type: constants.DELETE_PORTFOLIO_FROM_LIST,
  payload: portfolioId
});

export const editPortfolioFromList = (portfolio) => ({
  type: constants.EDIT_PORTFOLIO,
  payload: portfolio
});

export const getDealsForAddToPortfolio = () => ({
  type: constants.GET_DEALS_FOR_ADD_TO_PORTFOLIO
});
export const getDealsForAddToPortfolioSuccess = (items) => ({
  type: constants.GET_DEALS_FOR_ADD_TO_PORTFOLIO_SUCCESS,
  payload: items
});

export const deleteDealFromPortfolioList = (portfolioId, dealId) => ({
  type: constants.DELETE_DEAL_FROM_PORTFOLIO_LIST,
  payload: { portfolioId, dealId }
});

export const addPortfolio = (portfolio) => ({
  type: constants.ADD_PORTFOLIO,
  payload: portfolio
});

export const getSharedPortfolioList = () => ({
  type: constants.GET_SHARED_PORTFOLIO_LIST
});

export const getSharedPortfolioListSuccess = (items) => ({
  type: constants.GET_SHARED_PORTFOLIO_LIST_SUCCESS,
  payload: items
});

export const getTopicsList = (items) => ({
  type: constants.GET_TOPICS_LIST,
  payload: items
});

export const getAllTopics = (topics) => ({
  type: constants.GET_ALL_TOPICS,
  payload: topics
});

export const deleteAllTopicsSelectedFilters = () => ({
  type: constants.DELETE_ALL_TOPICS_FILTERS
});

export const getTopicsDataSuccess = (items) => ({
  type: constants.GET_TOPICS_DATA,
  payload: items
});

export const updateTopicsFilters = (filters) => ({
  type: constants.UPDATE_TOPICS_FILTERS,
  payload: filters
});

export const updateTopicPortfolioFilters = (filters) => ({
  type: constants.UPDATE_TOPICS_PORTFOLIO_FILTERS,
  payload: filters
});

export const updateTopicDocumentFilters = (filters) => ({
  type: constants.UPDATE_TOPICS_DOCUMENT_FILTERS,
  payload: filters
});

export const updateTopicsAvailableFilters = (filters) => ({
  type: constants.UPDATE_TOPICS_AVAILABLE_FILTERS,
  payload: filters
});

export const downloadTopic = (status) => ({
  type: constants.DOWNLOAD_TOPIC,
  payload: status
});

export const updateDealsSelectedFilters = (filters) => ({
  type: constants.UPDATE_DEALS_SELECTED_FILTERS,
  payload: filters
});

export const deleteAllDealsSelectedFilters = () => ({
  type: constants.DELETE_ALL_DEALS_FILTERS
});

export const dealListSearch = (search) => ({
  type: constants.DEAL_LIST_SEARCH,
  payload: search
});

export const loadProducts = () => ({
  type: constants.LOAD_PRODUCTS
});

export const loadProductsSuccess = (products) => ({
  type: constants.LOAD_PRODUCTS_SUCCESS,
  payload: products
});

export const getUpdatedProducts = (products) => ({
  type: constants.GET_UPDATED_PRODUCTS,
  payload: products
});

export const getUserComparisons = (items) => ({
  type: constants.GET_USER_COMPARISONS,
  payload: items
});

export const getDealsForComparison = () => ({
  type: constants.GET_DEALS_FOR_COMPARISON
});

export const getDealsForComparisonSuccess = (items) => ({
  type: constants.GET_DEALS_FOR_COMPARISON_SUCCESS,
  payload: items
});

export const getLinkForFrame = (link) => ({
  type: constants.GET_LINK_FOR_FRAME,
  payload: link
});

export const getCurrentComparison = (row) => ({
  type: constants.GET_CURRENT_COMPARISON,
  payload: row
});

export const getTopicsGroups = () => ({
  type: constants.GET_TOPICS_GROUPS
});

export const getTopicsGroupsSuccess = (items) => ({
  type: constants.GET_TOPICS_GROUPS_SUCCESS,
  payload: items
});
