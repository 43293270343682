import moment from 'moment';
import { getCustomFieldDefinition } from 'src/utils/common';
import { DEALS, FAVORITES } from 'src/MainLayout/pathesHandler';
import { INIT_PAGE } from '../constants';

export const areProductsTheSame = (arr) => {
  const products = new Set(arr.map((item) => item.product));
  return products.size <= 1 && (!products.has(null) || null);
};
export const INPUT_PROPS = {
  isNumericFilter: true
};

export const emptyDealsData = {
  itemCounter: 0,
  pageCounter: 0,
  deals: [],
  filterUniqueValues: {
    customFieldMap: {},
    latestIndentureList: [],
    currentDocSourceList: [],
    otherInfoList: [],
    latestMinorDocList: [],
    currencyList: [],
    dealTypeList: [],
    cloStatusList: []
  }
};

export const buildCustomFieldDefinitions = (allProducts, selectedProducts, filterOperators) => {
  if (selectedProducts.length === 1) {
    return (
      selectedProducts[0]?.customFields?.map((customField) =>
        getCustomFieldDefinition(customField, filterOperators)
      ) || []
    );
  }
  return allProducts
    .flatMap((product) => product.customFields)
    .filter((customField) => customField.isKeyField)
    .map((customField) => getCustomFieldDefinition(customField, filterOperators));
};

export const handleChangeRowsPerPage = (
  event,
  data,
  page,
  setPage,
  handleItemsPerPage,
  tableSettings
) => {
  const parsedItemPerPage = parseInt(event.target.value, 10);

  if (page > data.itemCounter / parsedItemPerPage) {
    setPage(Math.ceil(data.itemCounter / parsedItemPerPage));
  }
  const itemPerPage = tableSettings.pagination?.itemPerPage;
  if (itemPerPage > parsedItemPerPage && page === Math.ceil(data.itemCounter / itemPerPage)) {
    setPage(INIT_PAGE);
  }
  handleItemsPerPage(parsedItemPerPage);
};

export const dateTimeFilterValue = (operator, date, callback) => {
  const time = operator === 'isOnOrAfter' ? '00:00:00' : '23:59:59';
  const modifyDate = date ? moment(date).format(`YYYY-MM-DDT${time}`) : date;
  callback(modifyDate);
};

const definitionsHaveItemField = (definitions, item) =>
  definitions.some((definition) => definition.field === item.field);

export const getFilteredItems = (items, prevDefinitions, definitions) =>
  items.filter(
    (item) =>
      !(
        definitionsHaveItemField(prevDefinitions, item) &&
        !definitionsHaveItemField(definitions, item)
      )
  );

export const getClassName = (location, classes) => {
  switch (location) {
    case DEALS:
      return classes.dealsTopics;
    case FAVORITES:
      return classes.portfolios;
    default:
      return classes.wrapper;
  }
};
