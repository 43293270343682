import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import useStyles from './styles';

export const ColumnHeader = ({ title, src, style }) => {
  const classes = useStyles();
  return (
    <Grid style={style} className={classes.columnHeaderContainer}>
      <Typography className={classes.columnHeader} variant="body2">
        {src && <img className={classes.icon} src={src} alt="icon" />}
        {title}
      </Typography>
    </Grid>
  );
};

ColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
  style: PropTypes.object
};
