import axios from 'axios';

export const getTopic = async () => {
  const { data } = await axios.get('/topic');
  return data;
};

export const getTopicsProductIds = async (topicId) => {
  const { data } = await axios.get(`/topic/${topicId}/products`);
  return data;
};

export const getActiveTopicByPortfolio = async (portfolioId) => {
  const { data } = await axios.get(`/topic/active/portfolio/${portfolioId}`);
  return data;
};

export const getFilteredActiveTopic = async (body) => {
  const { data } = await axios.post('/topic/filter/active', body);
  return data;
};

export const postTopics = async (body, params) => {
  const { data } = await axios.post(`/topic/filter`, body, { params });
  return data;
};

export const postTopicsFilters = async (body) => {
  const { data } = await axios.post(`/topic/filter/unique/values`, body);
  return data;
};

export const postTopicCreateNew = async (params) => {
  await axios.post('/topic', params);
};

export const postTopicsExport = async (body, params) => {
  const { data } = await axios.post(`/topic/filter/export`, body, { params });
  return data;
};

export const getTopicExport = async (params) => {
  const { data } = await axios.get('/topic/export', { params });
  return data;
};

export const postTopicImport = async (formData, requestId) => {
  const { data } = await axios.post(`/topic/import?requestId=${requestId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  return data;
};

export const putTopicOrder = async (body) => {
  const { data } = await axios.put('/topic/order', body);
  return data;
};

export const putTopic = async (topicId, params) => {
  await axios.put(`/topic/${topicId}`, params);
};

export const deleteTopic = async (topicId) => {
  await axios.delete(`/topic/${topicId}`);
};
