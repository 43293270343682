import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import { Grid } from '@mui/material';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserPool from 'src/UserPool';
import { logout } from 'src/redux/actions';
import { postAnalytics } from 'src/services/api/analytics';
import clsx from 'clsx';
import { getUserTab } from 'src/utils/tabsUtils';
import { cloneDeep, set } from 'lodash';
import { Drawer, LogoHeader } from './mixins';
import { LOGOUT_EVENT } from '../../utils/constants/stringConstants';
import MainTab from '../MainTab/MainTab';
import ElasticModalPage from '../ElasticSearchModal/ElasticModalPage';
import useStyles from './styles';
import { getTabIcon } from '../utils';
import ElasticSearchButton from '../ElasticSearchButton/ElasticSearchButton';

export const SideBar = ({ mainContent }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.session.user.role);
  const [open, setOpen] = useState(() => {
    const userSettings = JSON.parse(localStorage.getItem('userSettings'));
    return userSettings?.sidebar?.sidebarOpen;
  });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const [currentTabs, setCurrentTabs] = useState([]);
  const [elasticSearchOpen, setElasticSearchOpen] = useState(false);

  useEffect(() => {
    setCurrentTabs(getUserTab(userRole));
    const cognitoUser = UserPool.getCurrentUser();
    setCurrentTabs(getUserTab(userRole));
    if (location.pathname === '/auth/login') {
      cognitoUser?.signOut();
      dispatch(logout());
    }
  }, [location.pathname, userRole]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('globalSearchModal') === 'true') {
      setElasticSearchOpen(true);
    }
  }, [location.search]);

  useEffect(() => {
    const changedState = cloneDeep(JSON.parse(localStorage.getItem('userSettings')));
    set(changedState, 'sidebar.sidebarOpen', open);
    localStorage.setItem('userSettings', JSON.stringify(changedState));
  }, [open]);

  const postAnalyticsEventHandler = async () => {
    const params = {
      event: LOGOUT_EVENT
    };

    await postAnalytics(params);
  };

  const handleLogout = async () => {
    await postAnalyticsEventHandler();
    const cognitoUser = UserPool.getCurrentUser();
    cognitoUser.signOut();
    dispatch(logout());
    const showAlert = localStorage.getItem('showAlert');
    const userSettings = localStorage.getItem('userSettings');
    localStorage.clear();
    localStorage.setItem('showAlert', showAlert);
    localStorage.setItem('userSettings', userSettings);
    navigate('/home');
    window.scrollTo(0, 0);
  };

  const handleDrawerOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const handleElasticModalOpen = () => {
    navigate(`${location.pathname}?globalSearchModal=true`);
    setElasticSearchOpen(!elasticSearchOpen);
  };

  return (
    <Box style={{ display: 'flex' }}>
      <ElasticModalPage
        open={elasticSearchOpen}
        onClose={() => {
          navigate(location.pathname);
          setElasticSearchOpen(false);
        }}
      />
      <Drawer variant="permanent" open={open}>
        <LogoHeader onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Grid className={classes.companyName}>
            <Link
              to="/home"
              component={NavLink}
              className={
                !open && isHovered ? clsx(classes.logoBox, classes.hideLogo) : classes.logoBox
              }
            >
              <img
                src="/images/logos/dealscribe-logo.svg"
                alt="Home page"
                className={classes.logoInternal}
              />
              <img
                src="/images/logos/dealscribe-text.svg"
                alt="Home page"
                className={classes.textInternal}
              />
            </Link>
          </Grid>
          <IconButton
            className={!open && isHovered ? classes.logoVisible : classes.logoInvisible}
            onClick={handleDrawerOpen}
          >
            <img
              src="/images/CloseSidebarIcon.svg"
              className={open ? classes.icon : classes.iconRotate}
              alt="Close icon"
            />
          </IconButton>
        </LogoHeader>
        <Divider />
        <Grid>
          <ElasticSearchButton
            onClick={handleElasticModalOpen}
            open={open}
            active={elasticSearchOpen}
          />
        </Grid>
        <List className={classes.list}>
          {currentTabs.map((tab) => (
            <MainTab
              open={open}
              icon={getTabIcon(tab.name)}
              tabName={tab.name}
              tabLink={tab.link}
              subTabs={tab.subTabs}
            />
          ))}
        </List>
        <div className={classes.bootomGroup}>
          <MainTab
            open={open}
            icon={<CallRoundedIcon fontSize="small" />}
            tabName="Contact us"
            tabLink="/contact-us"
          />
          <Grid onClick={handleLogout}>
            <MainTab
              open={open}
              icon={<img src="/images/sidebar/logout.svg" alt="logout" />}
              tabName="Sign out"
            />
          </Grid>
        </div>
      </Drawer>
      <Grid className={open ? classes.mainContentWrapper : classes.mainContentWrapperClosed}>
        {mainContent}
      </Grid>
    </Box>
  );
};

SideBar.propTypes = {
  mainContent: PropTypes.object
};
