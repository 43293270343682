import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import UserPool from '../UserPool';

const isUserLoggedIn = () => {
  const currentUser = UserPool.getCurrentUser();
  if (currentUser) {
    return new Promise((resolve) => {
      currentUser.getSession((err) => {
        if (err) {
          resolve(false);
        } else {
          currentUser.getUserData((err, res) => {
            if (err) {
              resolve(false);
            } else {
              const isEmailVerification = res.UserAttributes.some(
                (attr) => attr.Name === 'email_verified' && attr.Value === 'true'
              );
              const isStatusActive = res.UserAttributes.some(
                (attr) => attr.Name === 'custom:status' && attr.Value === 'Active'
              );
              resolve(isEmailVerification && isStatusActive);
            }
          });
        }
      });
    });
  }
  return Promise.resolve(false);
};

const OpenRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await isUserLoggedIn();
      setIsLoggedIn(loggedIn);
    };
    checkLoginStatus();
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

OpenRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default OpenRoute;
