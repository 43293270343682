import { parseFloatValueAsString } from './common';
import { NUMBER_TYPE } from './constants/stringConstants';

export const updateCustomFieldValues = (customFields, customFieldsValues) =>
  new Map(
    Array.from(customFieldsValues.entries()).map(([key, values]) => {
      const fieldType = customFields.get(key)?.type;
      if (fieldType === NUMBER_TYPE) {
        const updatedValues = values.map((value) => parseFloatValueAsString(value));
        return [key, updatedValues];
      }
      return [key, values];
    })
  );
