import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { LinearProgress, Link } from '@mui/material';
import useStyles from './styles';
import PageContent from './PageContent';
import CustomButton from '../CustomButton/CustomButton';
import { DEFAULT_DOCUMENT_CONTENT_COUNT } from '../../utils/constants/numericConstants';

const DocumentContentCard = ({
  data,
  searchValue,
  getFullDocumentContentValues,
  isQuotedCriteria
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateCardWithFullResponse = async () => {
    setLoading(true);
    await getFullDocumentContentValues(data.documentId);
    setLoading(false);
  };

  const toggleExpand = () => {
    if (data.values.length <= DEFAULT_DOCUMENT_CONTENT_COUNT + 1) {
      updateCardWithFullResponse();
    }
    setIsExpanded((prev) => !prev);
  };

  const visibleValues = isExpanded
    ? data.values
    : data.values.slice(0, DEFAULT_DOCUMENT_CONTENT_COUNT);

  return (
    <div className={classes.cardFullWidth}>
      <div className={classes.cardTitleGroup}>
        <img src="/images/elasticsearch/pdf-icon.svg" alt="pdf-icon" />
        <Link
          className={classes.cardTitleWithWrap}
          href={`/pdf/deal/${data.dealId}/document-pdf/${data.documentId}`}
          underline="none"
          target="_blank"
        >
          {data.documentName}
        </Link>
      </div>
      <div className={classes.cardContent}>
        {visibleValues.map((value) => (
          <PageContent
            value={value}
            searchValue={searchValue}
            url={`/pdf/deal/${data.dealId}/document-pdf/${data.documentId}?contentId=${value.id}`}
            isQuotedCriteria={isQuotedCriteria}
          />
        ))}
        {data.values.length > DEFAULT_DOCUMENT_CONTENT_COUNT && (
          <div className={classes.cardPdfFooter}>
            <CustomButton
              onClick={toggleExpand}
              placeholder={isExpanded ? 'Hide all pages' : 'Show all pages'}
              variant="text"
              size="medium"
              textColor="secondary"
              rightIcon={isExpanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              classes={classes}
            />
          </div>
        )}
        {loading && <LinearProgress />}
      </div>
    </div>
  );
};

DocumentContentCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string,
  getFullDocumentContentValues: PropTypes.func,
  isQuotedCriteria: PropTypes.bool
};

export default DocumentContentCard;
