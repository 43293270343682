import { makeStyles } from '@mui/styles';
import { LAPTOP_WIDTH } from 'src/utils/constants/numericConstants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.post.subtitle,
    flexDirection: 'column',
    padding: '61px 120px 48px 120px',
    marginTop: 'auto',
    [theme.breakpoints.down(LAPTOP_WIDTH)]: {
      flexDirection: 'column',
      padding: '61px 80px 48px 80px'
    }
  },
  divider: {
    display: 'flex',
    width: '100%',
    height: '1px',
    border: '1px',
    opacity: '35%',
    backgroundColor: '#D1D5DB'
  },
  adressWrapper: {
    marginBottom: '32px',
    gap: '123px',
    [theme.breakpoints.down(LAPTOP_WIDTH)]: {
      justifyContent: 'space-between',
      gap: '0px'
    }
  },
  city: {
    color: theme.palette.white,
    fontWeight: '700',
    lineHeight: '36px'
  },
  country: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#696969',
    marginBottom: '4px'
  },
  adress: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#D1D5DB'
  },
  adressBlock: {
    display: 'flex',
    flexDirection: 'column'
  },
  terms: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    color: '#9CA3AF',
    textDecoration: 'none'
  },
  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    alignItems: 'center'
  },
  copyright: {
    fontWeight: 500,
    lineHeight: '21px',
    color: '#650E0E'
  },
  linkedInIcon: {
    color: theme.palette.white,
    width: '32px',
    height: '32px'
  },
  contactEmail: {
    fontSize: '16px',
    color: theme.palette.white,
    fontWeight: 700,
    lineHeight: '24px',
    textDecoration: 'underline'
  },
  termsContainer: {
    display: 'flex',
    gap: '121px',
    alignItems: 'center'
  },
  termsAndAbout: {
    display: 'flex',
    gap: '36px'
  }
}));

export default useStyles;
