import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
    width: '100%'
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32.74px',
    color: '#374151',
    marginBottom: '12px'
  },
  modalWrapper: {
    width: '100%',
    minHeight: '395px',
    padding: '23px 24px',
    gap: '12px',
    borderRadius: '13px',
    backgroundColor: theme.palette.white,
    border: '1px solid #E5E7EB'
  },
  headerModal: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25.6px',
    color: theme.palette.text.subtitle,
    marginBottom: '12px'
  },
  inputFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  textArea: {
    marginBottom: '12px'
  },
  input: {
    '& .MuiOutlinedInput-root': {
      height: '41px'
    },
    '& .MuiInputLabel-root': {
      marginTop: '2px'
    }
  },
  customButton: {
    height: '43px',
    padding: '8px 18px',
    gap: '8px',
    '&:disabled': {
      backgroundColor: theme.palette.gray.main,
      height: '43px',
      padding: '8px 18px',
      gap: '8px'
    }
  },
  customButtonContent: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px'
  },
  contactWrapper: {
    width: '100%',
    height: '186px',
    padding: '24px',
    borderRadius: '13px',
    border: '1px solid #E5E7EB',
    gap: '4px',
    backgroundColor: theme.palette.white
  },
  adressBlockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: ' 23px',
    width: '100%'
  },
  city: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32.74px',
    color: '#374151'
  },
  country: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '19.2px',
    color: '#7E89A1'
  },
  adress: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25.6px',
    color: theme.palette.post.subtitle
  },
  contactEmail: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '25.6px',
    color: theme.palette.post.subtitle,
    textDecoration: 'underline #111111'
  }
}));
