import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    marginBottom: '12px',
    height: '89px',
    backgroundColor: theme.palette.white,
    padding: '15px 0px 0px 24px',
    borderBottom: '1px solid #E5E9F2'
  },
  container: {
    display: 'flex',
    gap: '24px'
  },
  tabName: {
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '28.64px',
    color: ' #1C1C1C',
    marginBottom: '16px'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '29px'
  },
  tab: {
    color: theme.palette.text.subtitle,
    fontWeight: 500,
    fontSize: '16px',
    height: '29px',
    lineHeight: '21.82px',
    textDecoration: 'none !important'
  },
  activeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '29px',
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 500,
    fontSize: '16px',
    height: '29px',
    lineHeight: '21.82px',
    textDecoration: 'none !important'
  }
}));

export default useStyles;
